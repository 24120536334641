import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/IgorSidorov/photo_1.webp';
import d_marcina_two from '../images/IgorSidorov/photo_2.webp';
import d_marcina_three from '../images/IgorSidorov/photo_3.webp';
import d_marcina_fore from '../images/IgorSidorov/photo_4.webp';
import d_marcina_five from '../images/IgorSidorov/photo_5.webp';
import d_marcina_Six from '../images/IgorSidorov/photo_6.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import igorSidorov from '../images/IgorSidorov.webp';

const ActorsInfo = () => {
  return (
    <>
        <Header/>
        <div className="actors-info-page">
          <div className="actors-info-page-content">
              <div className="actor-photo">
                <img src={igorSidorov} alt="Actor" />
              </div>
              <div className="actor-info">
                <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                <h2>Игорь Сидоров</h2>
                <h3>дата рождения: 19 ноября 2002 г. (21 год)</h3>
                <p>Рост - 185 см, размер одежды – 46</p>
                <p>Цвет волос – каштановый</p>
                <p>Цвет глаз – голубой</p>
                <h4>Закончил ИТКиТ(быв. Митро)</h4>
              </div>
              <div className="social">
                <div className="social-icons">
                  <img src={instagram} alt="Instagram" />
                  <img src={telegram} alt="Telegram" />
                  <img src={vk} alt="VK" />
                </div>
                <div className="social-number">
                  <p>Агент: Маргарита</p>
                  <p>+7(961)256-59-68</p>
                </div>
              </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className='actor-info-film'>
              <h2>фильмография</h2>
              <h3>2021</h3>
              <p>
                Шерлок — Убийца
                Короткометражный фильм
                Вторая главная роль
                Режиссёр: Абышкина Ирина
              </p>
              <h3>2021</h3>
              <p>
                В комнате — Пианист
                Короткометражный фильм
                Эпизод
                Режиссёр: Елизавета Журавлева
              </p>
              <h3>2022</h3>
              <p>
                Глаза во сне (рассказ Габриэля Г.М. "Глаза во сне") — Тёмная сущность
                Короткометражный фильм
                Эпизод
                Режиссёр: Дамир Минязов
              </p>
            </div>
            <div className='actor-info-nav'>
            <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
              <h2>навыки</h2>
              <h3>умения</h3>
              <p>
                танец: народный 
              </p>
              <p>
                вокал: оперный
              </p>
              <p>
                вокал: мюзикл
              </p>
              <p>
                вокал: хип-хоп 
              </p>
              <p>
                фехтование
              </p>
              <p>
                гитара
              </p>
              <p>
                фортепиано 
              </p>
              <p>
                Плавание
              </p>
              <h3>языки</h3>
              <p>
                русский - родной
              </p>
              <p>
                английский - разговорный
              </p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
            <img src={d_marcina_fore} alt="Gallery 4" />
            <img src={d_marcina_five} alt="Gallery 5" />
            <img src={d_marcina_Six} alt="Gallery 6" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
        </div>   
    </>
  );
};

export default ActorsInfo;