import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/TanyaRychkova/photo_2.webp';
import d_marcina_two from '../images/TanyaRychkova/photo_3.webp';
import d_marcina_three from '../images/TanyaRychkova/photo_4.webp';
import d_marcina_fore from '../images/TanyaRychkova/photo_5.webp';
import d_marcina_five from '../images/TanyaRychkova/photo_6.webp';
import d_marcina_Six from '../images/TanyaRychkova/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/TanyaRychkova/photo_1.webp';

const ActorsInfo = () => {
  return (
    <>
        <Header/>
        <div className="actors-info-page">
          <div className="actors-info-page-content">
              <div className="actor-photo">
                <img src={photo_1} alt="Actor" />
              </div>
              <div className="actor-info">
                <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                <h2>Татьяна Рычкова</h2>
                <h3>Возраст: 31 год</h3>
                <p>Рост - 160 см</p>
                <p>цвет волос - русые (длинные)</p>
                <p>цвет глаз - каре-зеленый</p>
                <h4>Закончила: ТИ им. Б.Щукина, 2019, Мастерская В.П. Николаенко </h4>
              </div>
              <div className="social">
                <div className="social-icons">
                  <img src={instagram} alt="Instagram" />
                  <img src={telegram} alt="Telegram" />
                  <img src={vk} alt="VK" />
                </div>
                <div className="social-number">
                  <p>Агент: Алика</p>
                  <p>8(920)522-21-71</p>
                </div>
              </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className='actor-info-film'>
              <h2>фильмография</h2>
              <h3>2024</h3>
              <p>
                След (Марина Дмитриевна Русакова
              </p>
              <h3>2024</h3>
              <p>
                СашаТаня (мама)
              </p>
              <h3>2024</h3>
              <p>
                Великолепная пятерка 6 (Софья Карпинская)
              </p>
              <h3>2024</h3>
              <p>
                Олень (девушка на свидании)
              </p>
              <h3>2024</h3>
              <p>
                Канитель (к/м) (баба)
              </p>
              <h3>2024</h3>
              <p>
                Возвращение (к/м)
              </p>
              <h3>2024</h3>
              <p>
                Старец (Алина)
              </p>
              <h3>2023</h3>
              <p>
                Старец (Алина)
              </p>
              <h3>2023</h3>
              <p>
                Голоса ушедших душ (Маша)
              </p>
              <h3>2017</h3>
              <p>
                Спасатель (к/м) (девушка
              </p>
            </div>
            <div className='actor-info-nav'>
            <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
              <h2>навыки</h2>
              <h3>умения</h3>
              <p>
                Танцы: балет, хип-хоп, народные
              </p>
              <p>
                Спорт: аэробика
              </p>
              <p>
                Инструменты: фортепиано
              </p>
              <p>
                Пение: общетеатральное
              </p>
              <p>
                Права: В
              </p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
            <img src={d_marcina_fore} alt="Gallery 4" />
            <img src={d_marcina_five} alt="Gallery 5" />
            <img src={d_marcina_Six} alt="Gallery 6" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
        </div>   
    </>
  );
};

export default ActorsInfo;