import React from 'react';
import './ActorModal.css'; 

const ItemList = ({ items, onDelete, onEdit }) => (
  <ul className="gallery-actor">
    {items.map(item => (
      <li key={item.id} className='gallery-item-actor'>
        <img src={item.mainImageUrl} alt={`${item.name} main`} />
        <div className='list-button'>
          <button onClick={() => onEdit(item)}>Изменить</button>
          <button onClick={() => onDelete(item.id)}>Удалить</button>
        </div>
      </li>
    ))}
  </ul>
);

export default ItemList;