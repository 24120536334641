import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/EkaterinaVoskresenskaya/photo_2.webp';
import d_marcina_two from '../images/EkaterinaVoskresenskaya/photo_3.webp';
import d_marcina_three from '../images/EkaterinaVoskresenskaya/photo_4.webp';
import d_marcina_fore from '../images/EkaterinaVoskresenskaya/photo_5.webp';
import d_marcina_five from '../images/EkaterinaVoskresenskaya/photo_6.webp';
import d_marcina_Six from '../images/EkaterinaVoskresenskaya/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/EkaterinaVoskresenskaya/photo_1.webp';

const ActorsInfo = () => {
    return (
      <>
          <Header/>
          <div className="actors-info-page">
            <div className="actors-info-page-content">
                <div className="actor-photo">
                  <img src={photo_1} alt="Actor" />
                </div>
                <div className="actor-info">
                  <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                  <h2>Екатерина Воскресенская</h2>
                  <h3>Возраст: 38 лет</h3>
                  <p>Рост - 167 см</p>
                  <p>Цвет волос - русые (до плеч)</p>
                  <p>Цвет глаз - серо-голубой</p>
                  <h4>Закончила: ИСИ, 2008, Мастерская Е.В. Радомысленского. Центр Михаила Чехова, Актерское мастерство и сценическая речь, Мастерская В.Г. Байчера</h4>
                </div>
                <div className="social">
                  <div className="social-icons">
                    <img src={instagram} alt="Instagram" />
                    <img src={telegram} alt="Telegram" />
                    <img src={vk} alt="VK" />
                  </div>
                  <div className="social-number">
                    <p>Агент: Маргарита</p>
                    <p>+7(961)256-59-68</p>
                  </div>
                </div>
            </div>
          </div>
          <div className="actor-details">
            <div className="actor-info-details">
              <div className='actor-info-film'>
                <h2>фильмография</h2>
                <h3>2024</h3>
                <p>
                  Первый раз
                </p>
                <p>
                  Исходники (к/м) - Львовна
                </p>
                <h3>2023</h3>
                <p>
                  Счастливый день (к/м) - Анна
                </p>
                <h2>Роли в театре</h2>
                <h3>2023</h3>
                <p>
                  театр «Нуар» - «Великий Гэтсби», «Маньяк»
                </p>
                <h3>2008-2009</h3>
                <p>
                  Московский Драматический театр на Перовской - «Село Степанчиково», «Тартюф», «Ромео и Джульетта», «Кошкин дом», «Муха-Цокотуха»
                </p>
              </div>
              <div className='actor-info-nav'>
              <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
                <h2>навыки</h2>
                <h3>умения</h3>
                <p>
                  Танцы: степ
                </p>
                <p>
                  Спорт: горные лыжи
                </p>
                <p>
                  Пение: академический, народный, общетеатральное
                </p>
                <h3>языки</h3>
                <p>
                  Английский
                </p>
                <h3>инструменты</h3>
                <p>
                  Балалайка, фортепиано, укулеле
                </p>
                <h3>права</h3>
                <p>
                  В
                </p>
              </div>
            </div>
          </div>
          <div className="gallery_info">
            <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
            <h2>ГАЛЕРЕЯ</h2>
            <div className="gallery-images_info">
              <img src={d_marcina_one} alt="Gallery 1" />
              <img src={d_marcina_two} alt="Gallery 2" />
              <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
              <img src={d_marcina_fore} alt="Gallery 4" />
              <img src={d_marcina_five} alt="Gallery 5" />
              <img src={d_marcina_Six} alt="Gallery 6" />
            </div>
          </div>
          <div className="video">
            <img src={d_marcina_video} alt="Video Thumbnail" />
            <h2>ВИДЕОВИЗИТКА</h2>
            <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
          </div>   
      </>
    );
  };

export default ActorsInfo;