import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/EkaterinaChermnykh/photo_2.webp';
import d_marcina_two from '../images/EkaterinaChermnykh/photo_3.jpg';
import d_marcina_three from '../images/EkaterinaChermnykh/photo_4.jpg';
import d_marcina_fore from '../images/EkaterinaChermnykh/photo_5.webp';
import d_marcina_five from '../images/EkaterinaChermnykh/photo_6.webp';
import d_marcina_Six from '../images/EkaterinaChermnykh/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/EkaterinaChermnykh/photo_1.webp';

const ActorsInfo = () => {
    return (
      <>
          <Header/>
          <div className="actors-info-page">
            <div className="actors-info-page-content">
                <div className="actor-photo">
                  <img src={photo_1} alt="Actor" />
                </div>
                <div className="actor-info">
                  <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                  <h2>Екатерина Чермных</h2>
                  <h3>Возраст: 34 года</h3>
                  <p>Рост - 170 см</p>
                  <p>Цвет волос - блонд</p>
                  <p>Цвет глаз - голубой</p>
                  <h4>Школа актерского мастерства Free Film School</h4>
                </div>
                <div className="social">
                  <div className="social-icons">
                    <img src={instagram} alt="Instagram" />
                    <img src={telegram} alt="Telegram" />
                    <img src={vk} alt="VK" />
                  </div>
                  <div className="social-number">
                    <p>Агент: Алика</p>
                    <p>8(920)522-21-7</p>
                  </div>
                </div>
            </div>
          </div>
          <div className="actor-details">
            <div className="actor-info-details">
              <div className='actor-info-film'>
                <h2>фильмография</h2>
                <h3>Сериалы</h3>
                <p>
                  Любовная магия 1 сезон 18 серия - эпизод
                </p>
                <p>
                  Беспринципные 3 сезон 3 серия - эпизод
                </p>
                <p>
                  Amore more 1 сезон 1 серия - эпизод
                </p>
                <p>
                  Вампиры средней полосы 2 сезон 7 серия - эпизод
                </p>
                <p>
                  Содержанки.Перезагрузка - 5 серия - эпизод
                </p>
                <h3>Короткометражки</h3>
                <p>
                  Шторм, реж. Роман Дунешенко - участница клуба
                </p>
                <p>
                  Актрисы, реж. Маргарита Маслова - гл. роль
                </p>
                <h3>Спектакль</h3>
                <p>
                  Такие коллапсы, реж. Алина Никольская
                </p>
                <h3>Клипы</h3>
                <p>
                  Маракеш - Снова дым
                </p>
                <p>
                  Natalya - Москва
                </p>
                <p>
                  Far&High - Sans Vetements
                </p>
                <p>
                  Сергей Лазарев - Алый закат
                </p>
                <h3>Полнометражные фильмы</h3>
                <p>
                  «Иван Васильевич меняет все» - гость на балу
                </p>
              </div>
              <div className='actor-info-nav'>
              <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
                <h2>навыки</h2>
                <h3>умения</h3>
                <p>
                  Езда на лошади
                </p>
                <h3>языки</h3>
                <p>
                  Русский, Английский
                </p>
              </div>
            </div>
          </div>
          <div className="gallery_info">
            <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
            <h2>ГАЛЕРЕЯ</h2>
            <div className="gallery-images_info">
              <img src={d_marcina_one} alt="Gallery 1" />
              <img src={d_marcina_two} alt="Gallery 2" />
              <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
              <img src={d_marcina_fore} alt="Gallery 4" />
              <img src={d_marcina_five} alt="Gallery 5" />
              <img src={d_marcina_Six} alt="Gallery 6" />
            </div>
          </div>
          <div className="video">
            <img src={d_marcina_video} alt="Video Thumbnail" />
            <h2>ВИДЕОВИЗИТКА</h2>
            <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
          </div>   
      </>
    );
  };

export default ActorsInfo;