import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/AlexanderNikolayevichMakhrov/photo_2.webp';
import d_marcina_two from '../images/AlexanderNikolayevichMakhrov/photo_3.webp';
import d_marcina_three from '../images/AlexanderNikolayevichMakhrov/photo_4.jpg';
import d_marcina_fore from '../images/TanyaRychkova/photo_5.webp';
import d_marcina_five from '../images/TanyaRychkova/photo_6.webp';
import d_marcina_Six from '../images/TanyaRychkova/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/AlexanderNikolayevichMakhrov/photo_1.webp';

const ActorsInfo = () => {
    return (
      <>
          <Header/>
          <div className="actors-info-page">
            <div className="actors-info-page-content">
                <div className="actor-photo">
                  <img src={photo_1} alt="Actor" />
                </div>
                <div className="actor-info">
                  <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                  <h2>Махров Александр Николаевич</h2>
                  <h3>дата рождения: 04.12.1974</h3>
                  <p>Рост - 173 см</p>
                  <p>Цвет глаз - голубой</p>
                  <h4>Центр Михаила Чехова, 2024, Актерское мастерство и сценическая речь, Мастерская А.Н. Калинина</h4>
                  <h4>Центр Михаила Чехова, Актерское мастерство и сценическая речь, Мастерская Н.И. Лебедева</h4>
                </div>
                <div className="social">
                  <div className="social-icons">
                    <img src={instagram} alt="Instagram" />
                    <img src={telegram} alt="Telegram" />
                    <img src={vk} alt="VK" />
                  </div>
                  <div className="social-number">
                    <p>Агент: Маргарита</p>
                    <p>+7(961)2565968</p>
                  </div>
                </div>
            </div>
          </div>
          <div className="actor-details">
            <div className="actor-info-details">
              <div className='actor-info-film'>
                <h2>фильмография</h2>
                <h3>2024</h3>
                <p>
                  Невский. Близкий враг - Валерий Бердин
                </p>
                <p>
                  Наш спецназ 3 - Кекс
                </p>
                <p>
                  Салют, Начальник 2 - следователь
                </p>
                <p>
                  Орлинская. Молния Зевса - Волжанский
                </p>
                <h3>2023</h3>
                <p>
                  Великолепная пятерка 5 - Орехов
                </p>
                <p>
                  Психотерапевт (к/м) - пациент
                </p>
                <p>
                  Условный мент 5 - Иван
                </p>
                <p>
                  Кто там (к/м) - врач скорой помощи
                </p>
                <p>
                  Невидимая сторона (к/м) - отец
                </p>
                <h3>2022</h3>
                <p>
                  Фишер - сотрудник СИЗО
                </p>
              </div>
              <div className='actor-info-nav'>
              <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
                <h2>навыки</h2>
                <h3>умения</h3>
                <p>
                  Спорт: Бокс, Кикбоксинг
                </p>
              </div>
            </div>
          </div>
          <div className="gallery_info">
            <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
            <h2>ГАЛЕРЕЯ</h2>
            <div className="gallery-images_info">
              <img src={d_marcina_one} alt="Gallery 1" />
              <img src={d_marcina_two} alt="Gallery 2" />
              <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
            </div>
          </div>
          <div className="video">
            <img src={d_marcina_video} alt="Video Thumbnail" />
            <h2>ВИДЕОВИЗИТКА</h2>
            <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
          </div>   
      </>
    );
  };

export default ActorsInfo;