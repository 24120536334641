import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/AnatolyBukin/photo_2.webp';
import d_marcina_two from '../images/AnatolyBukin/photo_3.webp';
import d_marcina_three from '../images/AnatolyBukin/photo_4.webp';
import d_marcina_fore from '../images/AnatolyBukin/photo_5.webp';
import d_marcina_five from '../images/AnatolyBukin/photo_6.webp';
import d_marcina_Six from '../images/AnatolyBukin/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/AnatolyBukin/photo_1.webp';

const ActorsInfo = () => {
    return (
      <>
        <Header />
        <div className="actors-info-page">
          <div className="actors-info-page-content">
            <div className="actor-photo">
              <img src={photo_1} alt="Actor" />
            </div>
            <div className="actor-info">
              <img className="actor-info_absolute_imagas" src={info_iacon_one} alt="" />
              <h2>Анатолий Букин</h2>
              <h3>Возраст: 34 года</h3>
              <p>Рост: 181 см</p>
              <p>Цвет волос: шатен (короткие)</p>
              <p>Цвет глаз: серые</p>
              <h4>
                Закончил: Московский государственный институт культуры, 2016. Хабаровский краевой
                колледж искусств, 2019. Хабаровская государственная академия экономики и права, 2013.
                Останкино, телерадиожурналистика, Мастерская М. Зеленского.
              </h4>
            </div>
            <div className="social">
              <div className="social-icons">
                <img src={instagram} alt="Instagram" />
                <img src={telegram} alt="Telegram" />
                <img src={vk} alt="VK" />
              </div>
              <div className="social-number">
                <p>Агент: Маргарита</p>
                <p>+7(961)256-59-68</p>
              </div>
            </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className="actor-info-film">
              <h2>Фильмография</h2>
              <h3>2024</h3>
              <p>Мастодонт 2 - Александр Климов, сотрудник следственного комитета</p>
              <p>Живой товар (к/м) - Владимир Грохольский</p>
              <p>Некрасивая подружка - 22. Муха в янтаре - автомаляр кузовного ремонта</p>
            </div>
            <div className="actor-info-nav">
              <img className="actor-info-nav_absolute_imagas" src={info_iacon_two} alt="" />
              <h2>Навыки</h2>
              <h3>Умения</h3>
              <p>Танцы: эстрадные</p>
              <p>
                Спорт: коньки, плавание,<br /> горные лыжи, карате
              </p>
              <p>
                Пение: тенор-баритон, эстрадный,<br /> эстрадно-джазовый, фальцет, хип-хоп,<br /> рэп, рок,
                общеиеатральное, мюзикл, панк-рок
              </p>
              <h3>Языки</h3>
              <p>Английский</p>
              <h3>Права</h3>
              <p>В</p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className="gallery_absolute_imagas" src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className="vertical" alt="Gallery 3" />
            <img src={d_marcina_fore} alt="Gallery 4" />
            <img src={d_marcina_five} alt="Gallery 5" />
            <img src={d_marcina_Six} alt="Gallery 6" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className="video_absolute_imagas" src={info_iacon_fore} alt="" />
        </div>
      </>
    );
};

export default ActorsInfo;