import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/OlgaBorisevich/photo_2.webp';
import d_marcina_two from '../images/OlgaBorisevich/photo_3.webp';
import d_marcina_three from '../images/OlgaBorisevich/photo_4.webp';
import d_marcina_fore from '../images/OlgaBorisevich/photo_5.webp';
import d_marcina_five from '../images/OlgaBorisevich/photo_6.webp';
import d_marcina_Six from '../images/OlgaBorisevich/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/OlgaBorisevich/photo_1.webp';

const ActorsInfo = () => {
    return (
      <>
          <Header/>
          <div className="actors-info-page">
            <div className="actors-info-page-content">
                <div className="actor-photo">
                  <img src={photo_1} alt="Actor" />
                </div>
                <div className="actor-info">
                  <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                  <h2>Ольга Борисевич</h2>
                  <h3>Возраст: 34 года</h3>
                  <p>Рост - 175 см</p>
                  <p>цвет волос - блондин (короткие)</p>
                  <p>цвет глаз - серо-голубой</p>
                  <h4>Закончила: СПбГИК, 2013, Мастерская В.П. Маркова РГИСИ (бывш. СПбГАТИ, ЛНИТМиК), 2017, Мастерская Е.Е. Кузиной ВШСИ К. Райкина, 2022, Мастерская К.А. Райкина</h4>
                </div>
                <div className="social">
                  <div className="social-icons">
                    <img src={instagram} alt="Instagram" />
                    <img src={telegram} alt="Telegram" />
                    <img src={vk} alt="VK" />
                  </div>
                  <div className="social-number">
                    <p>Агент: Алика</p>
                    <p>8(920)522-21-71</p>
                  </div>
                </div>
            </div>
          </div>
          <div className="actor-details">
            <div className="actor-info-details">
              <div className='actor-info-film'>
                <h2>фильмография</h2>
                <h3>2021</h3>
                <p>
                  По следу монстра (серия «Христан») / в производстве. - Следователь Дедкова. Документальный фильм (главная роль)
                </p>
                <h3>2019</h3>
                <p>
                  Сюрприз - мама. Короткометражный фильм (главная роль)
                </p>
                <h3>2015</h3>
                <p>
                  С небес на землю - сотрудница издательства. Сериал. Эпизод.
                </p>
                <h2>Роли в театре</h2>
                <h3>2018</h3>
                <p>
                  Циники - роль введена режиссером. Театр: школа русской драмы (СПБ)
                </p>
                <h3>2014</h3>
                <p>
                  Шесть шагов к жизни - Алина Павловна. Театр НИТИ
                </p>
                <h3>2015</h3>
                <p>
                  Освенцим - Стефа. Театр НИТИ
                </p>
              </div>
              <div className='actor-info-nav'>
              <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
                <h2>навыки</h2>
                <h3>умения</h3>
                <p>
                  Танцы: современные, бачата, хип-хоп, контемп
                </p>
                <p>
                  Спорт: плавание, велосипед, роликовые коньки, самокат, бокс, верховая езда, фехтование, стрельба, сценический бой
                </p>
                <p>
                  Инструменты: ударные
                </p>
                <p>
                  Пение: хип-хоп
                </p>
                <h3>языки</h3>
                <p>
                  Английский
                </p>
              </div>
            </div>
          </div>
          <div className="gallery_info">
            <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
            <h2>ГАЛЕРЕЯ</h2>
            <div className="gallery-images_info">
              <img src={d_marcina_one} alt="Gallery 1" />
              <img src={d_marcina_two} alt="Gallery 2" />
              <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
              <img src={d_marcina_fore} alt="Gallery 4" />
              <img src={d_marcina_five} alt="Gallery 5" />
              <img src={d_marcina_Six} alt="Gallery 6" />
            </div>
          </div>
          <div className="video">
            <img src={d_marcina_video} alt="Video Thumbnail" />
            <h2>ВИДЕОВИЗИТКА</h2>
            <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
          </div>   
      </>
    );
  };

export default ActorsInfo;