import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/DariaMarkina/photo_1.jpg';
import d_marcina_one from '../images/DariaMarkina/photo_1.jpg';
import d_marcina_two from '../images/DariaMarkina/photo_2.jpg';
import d_marcina_three from '../images/DariaMarkina/photo_3.jpg';
import d_marcina_fore from '../images/DariaMarkina/photo_4.webp';
import d_marcina_five from '../images/DariaMarkina/photo_5.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'

const ActorsInfo = () => {
  return (
    <>
        <Header/>
        <div className="actors-info-page">
          <div className="actors-info-page-content">
              <div className="actor-photo">
                <img src={d_marcina} alt="Actor" />
              </div>
              <div className="actor-info">
                <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                <h2>Дарья Маркина</h2>
                <h3>дата рождения: 14 августа 2001г. (23 года)</h3>
                <p>Рост 177 см, размер одежды 42-44</p>
                <p>цвет волос - блонд</p>
                <p>цвет глаз - голубые</p>
                <h4>Закончила ЧУПО СПК, МШК продюсирование</h4>
              </div>
              <div className="social">
                <div className="social-icons">
                  <img src={instagram} alt="Instagram" />
                  <img src={telegram} alt="Telegram" />
                  <img src={vk} alt="VK" />
                </div>
                <div className="social-number">
                  <p>Агент Маргарита Кучер</p>
                  <p>+7(961) 256-59-68</p>
                  <p>Director@twoyagency.ru</p>
                </div>
              </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className='actor-info-film'>
              <h2>фильмография</h2>
              <h3>2022</h3>
              <p>
                Тишина — Дашка 
                Короткометражный фильм
                Главная роль
                Режиссёр: Сухова А.
              </p>
              <h3>2021</h3>
              <p>
                Вербатим «Родом из детства» — Алина
                Режиссёр: Горностаева Ю. В.
                Театр: Театрик на вековой
              </p>
            </div>
            <div className='actor-info-nav'>
            <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
              <h2>навыки</h2>
              <h3>умения</h3>
              <p>
                футбол
              </p>
              <p>
                гитара
              </p>
              <p>
                бокс
              </p>
              <p>
                верховая езда
              </p>
              <h3>языки</h3>
              <p>
                русский - родной
              </p>
              <p>
                английский - разговорный
              </p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
            <img src={d_marcina_fore} className='horizontal' alt="Gallery 4" />
            <img src={d_marcina_five} className='horizontal'alt="Gallery 5" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
        </div>   
    </>
  );
};

export default ActorsInfo;