import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/DariaVeselova/photo_2.webp';
import d_marcina_two from '../images/DariaVeselova/photo_3.jpg';
import d_marcina_three from '../images/DariaVeselova/photo_4.webp';
import d_marcina_fore from '../images/DariaVeselova/photo_5.webp';
import d_marcina_five from '../images/DariaVeselova/photo_6.webp';
import d_marcina_Six from '../images/DariaVeselova/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/DariaVeselova/photo_1.webp';

const ActorsInfo = () => {
    return (
      <>
        <Header />
        <div className="actors-info-page">
          <div className="actors-info-page-content">
            <div className="actor-photo">
              <img src={photo_1} alt="Actor" />
            </div>
            <div className="actor-info">
              <img className="actor-info_absolute_imagas" src={info_iacon_one} alt="" />
              <h2>Дарья Веселова</h2>
              <h3>Возраст: 26 лет</h3>
              <p>Рост: 170 см</p>
              <p>Цвет волос: блондин (короткие)</p>
              <p>Цвет глаз: голубые</p>
              <h4>
                Закончила: ИСИ, 2020, Мастерская И.В. Агапова. Музыкальная школа по классу хоровое пение
              </h4>
            </div>
            <div className="social">
              <div className="social-icons">
                <img src={instagram} alt="Instagram" />
                <img src={telegram} alt="Telegram" />
                <img src={vk} alt="VK" />
              </div>
              <div className="social-number">
                <p>Агент: Маргарита</p>
                <p>+7(961)256-59-68</p>
              </div>
            </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className="actor-info-film">
              <h2>Фильмография</h2>
              <p>Фильмография отсутствует</p>
            </div>
            <div className="actor-info-nav">
              <img className="actor-info-nav_absolute_imagas" src={info_iacon_two} alt="" />
              <h2>Навыки</h2>
              <h3>Умения</h3>
              <p>Спорт: бокс, конный спорт</p>
              <p>Пение: рок, мюзикл, джазовый</p>
              <p>Инструменты: барабанная установка, фортепиано</p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className="gallery_absolute_imagas" src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className="vertical" alt="Gallery 3" />
            <img src={d_marcina_fore} alt="Gallery 4" />
            <img src={d_marcina_five} alt="Gallery 5" />
            <img src={d_marcina_Six} alt="Gallery 6" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className="video_absolute_imagas" src={info_iacon_fore} alt="" />
        </div>
      </>
    );
  };

export default ActorsInfo;