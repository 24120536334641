import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/AshotEloyan/photo_1.webp';
import d_marcina_two from '../images/AshotEloyan/photo_2.webp';
import d_marcina_three from '../images/AshotEloyan/photo_3.webp';
import d_marcina_fore from '../images/AshotEloyan/photo_4.webp';
import d_marcina_five from '../images/AshotEloyan/photo_5.webp';
import d_marcina_video from '../images/AshotEloyan/video.mp4';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import ashotEloyan from '../images/AshotEloyan.webp';

const ActorsInfo = () => {
  return (
    <>
        <Header/>
        <div className="actors-info-page">
          <div className="actors-info-page-content">
              <div className="actor-photo">
                <img src={ashotEloyan} alt="Actor" />
              </div>
              <div className="actor-info">
                <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                <h2>Ашот Элоян</h2>
                <h3>дата рождения: 29 ноября 2002</h3>
                <p>Рост - 177 см</p>
                <p>цвет волос - брюнет</p>
                <p>цвет глаз - хамелеон</p>
              </div>
              <div className="social">
                <div className="social-icons">
                  <img href="https://www.instagram.com/iameloyan?igsh=NzRiMHgzdzB6Yjlu" src={instagram} alt="Instagram" />
                  <img href="https://t.me/eloyanneprivat" src={telegram} alt="Telegram" />
                  <img href="https://vk.com/iameloyan" src={vk} alt="VK" />
                </div>
                <div className="social-number">
                  <p>Агент: Алика</p>
                  <p>+7(920) 522-21-72</p>
                </div>
              </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className='actor-info-film'>
              <h2>фильмография</h2>
              <h3>2024</h3>
              <p>
                Сериал «Бим» 3 сезон - Илья
              </p>
              <p>
                Сериал «Первый номер» - камео
              </p>
              <p>
                Сериал «Универ. Молодые» - студент
              </p>
              <p>
                Фильм «Девятая планета» - солдат
              </p>
              <p>
                Сериал «мажор» - красивый иностранец
              </p>
            </div>
            <div className='actor-info-nav'>
            <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
              <h2>навыки</h2>
              <h3>умения</h3>
              <p>
                Импровизация
              </p>
              <p>
                Бокс
              </p>
              <p>
                бокс
              </p>
              <p>
                Читает рэп
              </p>
              <p>
                Картинг
              </p>
              <h3>языки</h3>
              <p>
                Армянский
              </p>
              <p>
                Английский
              </p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
            <img src={d_marcina_fore} className='horizontal' alt="Gallery 4" />
            <img src={d_marcina_five} className='horizontal'alt="Gallery 5" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
        </div>   
    </>
  );
};

export default ActorsInfo;