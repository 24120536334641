import React from 'react';
import './Home.css'; 
import Header from '../Header/Header'
import Banner from './Banner'

const Home = () => {
  return (
    <>
      <Header/>
      <Banner/>
    </>
  );
};

export default Home;