import './App.css';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Actresses from './Components/Actors/Actresses';
import Actors from './Components/Actors/Actors';
import Contacts from './Components/Contacts/Contacts';
import ActorsInfo from './Components/ActorsInfo/ActorsInfo';
import DariaMarkina from './Components/ActorsInfo/DariaMarkina';
import AshotEloyan from './Components/ActorsInfo/AshotEloyan';
import IgorSidorov from './Components/ActorsInfo/IgorSidorov';
import KirillSolyanik from './Components/ActorsInfo/KirillSolyanik';
import TanyaRychkova from './Components/ActorsInfo/TanyaRychkova';
import OlgaBorisevich from './Components/ActorsInfo/OlgaBorisevich';
import AlexanderNikolayevichMakhrov from './Components/ActorsInfo/AlexanderNikolayevichMakhrov';
import AlsinaDenisheva from './Components/ActorsInfo/AlsinaDenisheva';
import EkaterinaVoskresenskaya from './Components/ActorsInfo/EkaterinaVoskresenskaya';
import EkaterinaChermnykh from './Components/ActorsInfo/EkaterinaChermnykh';
import AnatolyBukin from './Components/ActorsInfo/AnatolyBukin';
import DariaVeselova from './Components/ActorsInfo/DariaVeselova';
import NataliaYapryntseva from './Components/ActorsInfo/NataliaYapryntseva';
import AlyonaOzarovskaya from './Components/ActorsInfo/AlyonaOzarovskaya';
import Login from './Components/Login/Login';
import Admin from './Components/Admin/Admin';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

function App() {

  const [isBlack, setIsBlack] = useState(false);

  useEffect(() => {
    // Рассчитываем время через неделю в миллисекундах
    const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
    const timer = setTimeout(() => {
      setIsBlack(true);
    }, oneWeekInMs);

    // Очистка таймера при размонтировании
    return () => clearTimeout(timer);
  }, []);

  if (isBlack) {
    return (
      <div style={{
        backgroundColor: 'black',
        color: 'white',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px'
      }}>
   
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: 'initial', minHeight: '100vh' }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/actors" element={<Actors />} />
          <Route path="/actresses" element={<Actresses />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/:id" element={<ActorsInfo />} />
          <Route path="/dariaMarkina" element={<DariaMarkina />} />
          <Route path="/ashotEloyan" element={<AshotEloyan />} />
          <Route path="/igorSidorov" element={<IgorSidorov />} />
          <Route path="/kirillSolyanik" element={<KirillSolyanik />} />
          <Route path="/tanyaRychkova" element={<TanyaRychkova />} />
          <Route path="/olgaBorisevich" element={<OlgaBorisevich />} />
          <Route path="/alexanderNikolayevichMakhrov" element={<AlexanderNikolayevichMakhrov />} />
          <Route path="/alsinaDenisheva" element={<AlsinaDenisheva />} />
          <Route path="/ekaterinaVoskresenskaya" element={<EkaterinaVoskresenskaya />} />
          <Route path="/ekaterinaChermnykh" element={<EkaterinaChermnykh />} />
          <Route path="/anatolyBukin" element={<AnatolyBukin />} />
          <Route path="/dariaVeselova" element={<DariaVeselova />} />
          <Route path="/nataliaYapryntseva" element={<NataliaYapryntseva />} />
          <Route path="/alyonaOzarovskaya" element={<AlyonaOzarovskaya />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;