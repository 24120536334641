import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/KirillSolyanik/photo_1.webp';
import d_marcina_two from '../images/KirillSolyanik/photo_2.webp';
import d_marcina_three from '../images/KirillSolyanik/photo_3.webp';
import d_marcina_fore from '../images/KirillSolyanik/photo_4.webp';
import d_marcina_five from '../images/KirillSolyanik/photo_5.webp';
import d_marcina_Six from '../images/KirillSolyanik/photo_6.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import kirillSolyanik from '../images/KirillSolyanik.webp';

const ActorsInfo = () => {
  return (
    <>
        <Header/>
        <div className="actors-info-page">
          <div className="actors-info-page-content">
              <div className="actor-photo">
                <img src={kirillSolyanik} alt="Actor" />
              </div>
              <div className="actor-info">
                <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                <h2>Соляник Кирилл</h2>
                <h3>дата рождения: 14 июля 1999</h3>
                <p>Рост - 179 см</p>
                <p>цвет волос - русый</p>
                <p>цвет глаз - голубой</p>
                <h4>Закончил ВГИК, 2021 Фильмография</h4>
              </div>
              <div className="social">
                <div className="social-icons">
                  <img src={instagram} alt="Instagram" />
                  <img src={telegram} alt="Telegram" />
                  <img src={vk} alt="VK" />
                </div>
                <div className="social-number">
                  <p>Агент: Алика</p>
                  <p>8(920)522-21-71</p>
                </div>
              </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className='actor-info-film'>
              <h2>фильмография</h2>
              <h3>2024</h3>
              <p>
                Самогон
              </p>
              <h3>2024</h3>
              <p>
                Больше, чем футбол
                игрок сборной России
              </p>
              <h3>2024</h3>
              <p>
                Игры
                велосипедист
              </p>
              <h3>2024</h3>
              <p>
                Я иду тебя искать 8
                Никита
              </p>
              <h3>2024</h3>
              <p>
                Противостояние
                Обершутце
              </p>
              <h3>2023</h3>
              <p>
                Ивановы-Ивановы
                звукооператор
              </p>
              <h3>2023</h3>
              <p>
                Бедные Абрамовичи
                посетитель ресторана
              </p>
              <h3>2023</h3>
              <p>
                Когда по телевизору белый шум (к/м)
                главный герой
              </p>
            </div>
            <div className='actor-info-nav'>
            <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
              <h2>навыки</h2>
              <h3>умения</h3>
              <p>
                Танцы - бальные, народные, модерн 
              </p>
              <p>
                Жонглирование
              </p>
              <p>
                Вокал - мьюзикл, эстрадный, Фортепиано
              </p>
              <p>
                верховая езда
              </p>
              <p>
                Футбол
              </p>
              <p>
                хоккей
              </p>
              <p>
                баскетбол
              </p>
              <p>
                фигурное катание
              </p>
              <p>
                гимнастика
              </p>
              <h3>языки</h3>
              <p>
                Английский
              </p>
              <p>
                Французский
              </p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
            <img src={d_marcina_fore} alt="Gallery 4" />
            <img src={d_marcina_five} alt="Gallery 5" />
            <img src={d_marcina_Six} alt="Gallery 6" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
        </div>   
    </>
  );
};

export default ActorsInfo;