import React from 'react';
import './ActorsInfo.css';
import instagram from '../images/instagram.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';
import d_marcina from '../images/d_marcina.svg';
import d_marcina_one from '../images/AlyonaOzarovskaya/photo_2.webp';
import d_marcina_two from '../images/AlyonaOzarovskaya/photo_3.webp';
import d_marcina_three from '../images/AlyonaOzarovskaya/photo_4.webp';
import d_marcina_fore from '../images/AlyonaOzarovskaya/photo_5.webp';
import d_marcina_five from '../images/AlyonaOzarovskaya/photo_6.webp';
import d_marcina_Six from '../images/AlyonaOzarovskaya/photo_7.webp';
import d_marcina_video from '../images/d_marcina_video.svg';
import info_iacon_one from '../images/info_iacon_one.svg';
import info_iacon_two from '../images/info_iacon_two.svg';
import info_iacon_three from '../images/info_iacon_three.svg';
import info_iacon_fore from '../images/info_iacon_fore.svg';
import Header from '../Header/Header'
import photo_1 from '../images/AlyonaOzarovskaya/photo_1.webp';

const ActorsInfo = () => {
  return (
    <>
        <Header/>
        <div className="actors-info-page">
          <div className="actors-info-page-content">
              <div className="actor-photo">
                <img src={photo_1} alt="Actor" />
              </div>
              <div className="actor-info">
                <img className='actor-info_absolute_imagas' src={info_iacon_one} alt="" />
                <h2>Алёна Озаровская</h2>
                <h3>Возраст: 24 года</h3>
                <p>Рост - 162 см</p>
                <p>цвет волос - рыжий (длинные)</p>
                <p>цвет глаз - зелёно-голубой</p>
                <h4>Закончила: ОМПК, 2022, Мастерская Л.Я. Меерсон</h4>
              </div>
              <div className="social">
                <div className="social-icons">
                  <img src={instagram} alt="Instagram" />
                  <img src={telegram} alt="Telegram" />
                  <img src={vk} alt="VK" />
                </div>
                <div className="social-number">
                  <p>Агент: Маргарита</p>
                  <p>+7(961)256-59-68</p>
                </div>
              </div>
          </div>
        </div>
        <div className="actor-details">
          <div className="actor-info-details">
            <div className='actor-info-film'>
              <h2>Роли в театре:</h2>
              <h3>2022 - 2024 Омский ТЮЗ</h3>
              <p>
                По щучьему велению». Роль – Царевна. Режиссёр-постановщик Осипов А.А., Старцева А.С. <br />
                «Преступление и наказание» Режиссёр-постановщик Д.А. Акимов. Роль – Софья Семёновна Мармеладова <br />
                «Островский. Игра в куклы». Роль – Снегурочка, Моль. Подружка. Режиссёр-постановщик Старцева А.С.<br />
                «Лисьи огни». Роль – Паук. Режиссёр-постановщик Черепанов А.В.<br />
                «Ревность, любовь и всякие глупости» Роль – Лиля. Режиссёр-постановщик Старцева А.С.<br />
                «Айболит и Бармалей». Роль – Команда Доктора Айболита. Режиссёр-постановщик Старцева А.С.<br />
                "Тот самый Карлсон". Роль – Малыш. Режиссёр-постановщик Черных С.Н.<br />
                «Приключения Ёжика и Медвежонка или обыкновенный необыкновенный день». Роль – Бабочка, Солнечный зайчик. Режиссёр-постановщик – Н.В. Бизина<br />
                "Проделки хитрецов" Режиссёр-постановщик Старцева А.С. Роль - Жена Эмира.<br />
                "Онегин" Режиссёр-постановщик – Ю.И. Печенежский. Роль – Рассказчик, участник оркестра<br />
                «Танцплощадка 41/45». Роль – Сладкоежка. Режиссёр-постановщик – В.А. Золотарь<br />
                «Герой нашего времени» по мотивам романа М.Ю. Лермонтова. Роль – Княжна Мери. Режиссёр-постановщик А.А. Опарин<br />
                «Василисса». Роль – Змейка лесная. Режиссёр-постановщик Старцева А.С.<br />
                «Сказки Пушкина». Роль – Волна, Богатырь, Пушкин. Режиссёр-постановщик Осипов А.А., Старцева А.С.<br />
                «Собачье сердце» Режиссёр-постановщик А.С. Шляпин. Роль – Ирина Сергеевна<br />
                "Щелкунчик" Режиссёр-постановщик Старцева А.С. Роль – Ида, Старая кукла, Мышь   <br />
              </p>
            </div>
            <div className='actor-info-nav'>
            <img className='actor-info-nav_absolute_imagas' src={info_iacon_two} alt="" />
              <h2>навыки</h2>
              <h3>умения</h3>
              <p>
                Танцы: контемп, джаз-модерн, бальные, <br /> вальс, грузинские, современные, общеиеатральные
              </p>
              <p>
                Спорт: волейбол, плавание, стрельба из лука, <br /> верховая езда, парная акробатика, <br /> коньки, лыжи, роликовые коньки 
              </p>
              <p>
                Пение: академический, народный, <br /> общетеатральное, сопрано
              </p>
              <p>
                Инструменты: укулеле, перкуссия
              </p>
              <p>
                Языки: английский, французский
              </p>
            </div>
          </div>
        </div>
        <div className="gallery_info">
          <img className='gallery_absolute_imagas' src={info_iacon_three} alt="" />
          <h2>ГАЛЕРЕЯ</h2>
          <div className="gallery-images_info">
            <img src={d_marcina_one} alt="Gallery 1" />
            <img src={d_marcina_two} alt="Gallery 2" />
            <img src={d_marcina_three} className='vertical' alt="Gallery 3" />
            <img src={d_marcina_fore} alt="Gallery 4" />
            <img src={d_marcina_five} alt="Gallery 5" />
            <img src={d_marcina_Six} alt="Gallery 6" />
          </div>
        </div>
        <div className="video">
          <img src={d_marcina_video} alt="Video Thumbnail" />
          <h2>ВИДЕОВИЗИТКА</h2>
          <img className='video_absolute_imagas' src={info_iacon_fore} alt="" />
        </div>   
    </>
  );
};

export default ActorsInfo;